const IMAGES = {
    // LOGO
    logo: require("../img/logo.png"),
    // LOGIN
    // alogin: require("../img/admin-login.png"),
    //HOME
    // b: require("../img/home/b.png"),
    // b1: require("../img/home/b1.png"),
    // b2: require("../img/home/b2.png"),
    // b3: require("../img/home/b3.png"),
    // b4: require("../img/home/b4.png"),
    // pgu: require("../img/products/safe.png"),
    // barcode
    // barcode: require("../img/barcode.jpg"),
    // HOME
    // trusted: require("../img/home/trusted.svg"),
    // register: require("./home/register.svg"),
    // verified: require("./home/verified.svg"),
    // privacy: require("./home/privacy.svg"),
    //
    // maint: require("./2.jpg"),
    // pinfo page
    phonepe: require("../img/products/phonepe.png"),
    gpay: require("../img/products/gpay.png"),
    paytm: require("../img/products/paytm.png"),
    // support
    support: require("../img/support.png"),
    // home slider
    paytm: require("../img/paytm.png"),
    gpay: require("../img/gpay.png"),
    phonepe: require("../img/phonepe.png"),
    upi: require("../img/upi.png"),
    wallet: require("../img/wallet.png"),
  };
  
  export default IMAGES;
  
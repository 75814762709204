import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setUser } from "../redux/features/userSlice.js";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import IMAGES from "../img/image";
import axios from "../Axios";
import getUserData from "../utils/userDataService.js";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import WalletIcon from "@mui/icons-material/Wallet";
import "./ProductInfo.css";

const ProductInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [userAllow, setUserAllow] = useState(false);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState("WALLET");
  const [paymentOptions, setPaymentOptions] = useState("");
  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [orderId, setOrderId] = useState(false);
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [balance, setBalance] = useState("");
  const [orderSuccess, setOrderSuccess] = useState(false);

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    }
  }

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setProductId(defaultId);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
  }, []);

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter User ID" : "Enter (   Zone ID   )"}`
      );
    }
    try {
      // setLoading(true);
      const idPro = productId.split("&")[0];
      const object = {
        region: product?.apiName === "smileOne" ? product?.region : "philippines",
        userid: userId,
        zoneid: zoneId,
        productid: product?.apiName === "smileOne" ? idPro : "213",
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setLoading(false);
        if (product?.user === res.data.user) {
          setUserAllow(true);
        } else {
          setUserAllow(false);
        }
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function checkPlaceOrder(e) {
    if (product?.api === "no") {
      if (userId === "") {
        return message.error("Some Fields are missing");
      }
    } else if (product.api === "yes" && product?.apiName === "yokcash") {
      if (
        product?.gameName === "Clash Of Clans" ||
        product?.gameName === "Free Fire"
      ) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
      }
      if (
        product?.gameName === "Genshin Impact" ||
        product?.gameName === "ML Region Luar" ||
        product?.gameName === "Mobile Legends"
      ) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
    } else {
      if (userId === "") {
        return message.error("Enter User ID");
      }
      if (zoneId === "") {
        return message.error("Enter Zone ID");
      }
      if (playerCheck === null) {
        return message.error("Check Your Player Name");
      }
    }

    if (product?.api === "yes") {
      if (product?.apiName === "yokcash") {
        if (mode === "UPI") {
          handleYokcashUpiOrder(e);
        } else {
          handleYokcashWalletOrder(e);
        }
      } else if (product?.apiName === "smileOne") {
        if (mode === "UPI") {
          handleSmileOneUpiOrder(e);
        } else {
          handleSmileOneWalletOrder(e);
        }
      } else if (product?.apiName === "moogold") {
        if (mode === "UPI") {
          handleMoogoldUpiOrder(e);
        } else {
          handleMoogoldWalletOrder(e);
        }
      }
    } else {
      if (mode === "UPI") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }

  // yokcash
  async function handleYokcashUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://exstorecredit.com/api/yokcash/status?orderId=${orderId}&email=${user?.email}&mobile=${user?.mobile}&name=${user?.fname}`,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      const response = await axios.post("/api/yokcash/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.payment_url) {
        window.location.href = response.data.data.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleYokcashWalletOrder(e) {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoading(true);
      const res = await axios.post("/api/yokcash/wallet", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setOrderSuccess(true);
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // smile
  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://exstorecredit.com/api/smile/status?orderId=${orderId}&email=${user?.email}&mobile=${user?.mobile}&name=${user?.fname}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      const response = await axios.post("/api/smile/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.payment_url) {
        window.location.href = response.data.data.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    const orderObject = {
      client_txn_id: orderId,
      userid: userId,
      zoneid: zoneId,
      productid: productId,
      region: product.region,
      customer_email: user?.email,
      customer_mobile: user?.mobile,
      pname: product?.name,
      amount: amount,
      price: selectedPrice,
    };

    setLoading(true);
    const res = await axios.post("/api/smile/wallet", orderObject, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (res.data.success) {
      message.success(res.data.message);
      setOrderSuccess(true);
      setLoading(false);
    } else {
      setLoading(false);
      message.error(res.data.message);
    }
  };

  // moogold
  async function handleMoogoldUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.gameName,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://exstorecredit.com/api/moogold/status?orderId=${orderId}&email=${user?.email}&mobile=${user?.mobile}&name=${user?.fname}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      const response = await axios.post("/api/moogold/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.payment_url) {
        window.location.href = response.data.data.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleMoogoldWalletOrder(e) {
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: product?.gameName,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };
      setLoading(true);
      const res = await axios.post("/api/moogold/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        setOrderSuccess(true);
      } else {
        setLoading(false);
        message.error(res.data.message);
        localStorage.setItem("orderProcess", "no");
      }
    } catch (error) {
      console.log(error);
    }
  }

  // manaul
  const handleUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        txn_note: userId + "@" + amount,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
        customer_email: user?.email,
        callback_url: `https://exstorecredit.com/api/payment/check-manual-upi-order?orderId=${orderId}&email=${user?.email}&mobile=${user?.mobile}&name=${user?.fname}`,
      };

      const response = await axios.post(
        "/api/payment/create-manual-upi-order",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.payment_url) {
        window.location.href = response.data.data.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        client_txn_id: orderId,
        userid: userId,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
      };

      setLoading(true);
      const res = await axios.post(
        "/api/payment/place-order-non-api",
        orderObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setLoading(false);
        message.success(res.data.message);
        setOrderSuccess(true);
      } else {
        message.error(res.data.message);
        setLoading(false);
        localStorage.setItem("orderProcess", "no");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      localStorage.setItem("orderProcess", "no");
    }
  };

  return (
    <Layout>
      {loading ? (
        <div className="loading-container">
          <div class="loading-wave">
            <div class="loading-bar"></div>
            <div class="loading-bar"></div>
            <div class="loading-bar"></div>
            <div class="loading-bar"></div>
          </div>
          <h4>Processing..</h4>
        </div>
      ) : orderSuccess ? (
        <div className="order-succcess-container">
          <h5>Order Successful</h5>
          <div className="order-recpt">
            <div className="order-item">
              <span>Product Name</span>
              <span>{product?.name}</span>
            </div>
            <div className="order-item">
              <span>Order Id</span>
              <span>{orderId}</span>
            </div>
            <div className="order-item">
              <span>User Id</span>
              <span>{userId}</span>
            </div>
            {zoneId !== "" && (
              <div className="order-item">
                <span>Zone Id</span>
                <span>{zoneId}</span>
              </div>
            )}
            <div className="order-item">
              <span>Pack</span>
              <span>{amount}</span>
            </div>
            <div className="order-item">
              <span>Wallet Balance</span>
              <span>{balance}</span>
            </div>
            <div className="order-item">
              <span>Balance After Order</span>
              <span>{balance - selectedPrice}</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="product-info-container">
          <div className="pic">
            <div className="product-info-img bg-fields">
              <div className="game-name">
                <img
                  src={`https://exstorecredit.com/${product?.image}`}
                  alt=""
                />
                <div>
                  <span>
                    <small>{product?.category}</small>
                  </span>
                  <h4>{product?.name}</h4>
                </div>
              </div>
              <p>{product?.desc}</p>
            </div>
            {/* ====================== DESC TWO ===============  */}
            {/* ====================== DESC TWO ===============  */}
            <div className="bg-fields d-none d-md-block d-lg-block">
              <p>{product?.descTwo}</p>
            </div>
          </div>

          <div className="product-info-content mb-2">
            {/* ====================== FIELDS ===============  */}
            {/* ====================== FIELDS ===============  */}
            <div className="bg-fields">
              <h5>
                <LooksOneIcon className="icon" /> Enter IDs
              </h5>
              {product?.apiName === "smileOne" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="User ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  <button className="buy-now" onClick={handleCheckPlayer}>
                    Check
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  {playerCheck !== null && userAllow && (
                    <p className="playername text-white bg-success">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                  {playerCheck !== null && !userAllow && (
                    <p className="playername text-white bg-danger">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "ML Region Luar" ||
                  product?.gameName === "Mobile Legends") ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  <button className="buy-now mb-2" onClick={handleCheckPlayer}>
                    Check Username
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  {playerCheck !== null && (
                    <p className="playername text-success">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "Genshin Impact" ||
                  product?.gameName === "Honkai Star Rail") ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <select
                    name="zoneid"
                    className="form-select"
                    onChange={(e) => setZoneId(e.target.value)}
                  >
                    <option value="">Select Server</option>
                    <option value="America">America</option>
                    <option value="Asia">Asia</option>
                    <option value="Europe">Europe</option>
                    <option value="TW_HK_MO">TW_HK_MO</option>
                  </select>
                  <span className="text-danger">
                    {error &&
                      "First Check your username by clicking check button"}
                  </span>
                </>
              ) : product?.apiName === "yokcash" &&
                (product?.gameName === "Free Fire" ||
                  product?.gameName === "Call Of Duty Mobile" ||
                  product?.gameName === "PUBG Mobile" ||
                  product?.gameName === "Valorant" ||
                  product?.gameName === "Clash Of Clans" ||
                  product?.gameName === "League of Legends Wild Rift") ? (
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tag}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              ) : product?.apiName === "moogold" &&
                product?.gameName === "15145" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="User ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                  <button className="buy-now" onClick={handleCheckPlayer}>
                    Check
                    {loading && (
                      <div class="spinner-grow spinner-grow-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                  {playerCheck !== null && (
                    <p className="playername text-success">
                      {playerCheck && "Username: " + playerCheck}
                    </p>
                  )}
                </>
              ) : product?.apiName === "moogold" &&
                product?.gameName === "428075" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <select
                    name="zoneid"
                    className="form-select"
                    onChange={(e) => setZoneId(e.target.value)}
                  >
                    <option value="">Select Server</option>
                    <option value="America">America</option>
                    <option value="Asia">Asia</option>
                    <option value="Europe">Europe</option>
                    <option value="TW, HK, MO">TW, HK, MO</option>
                  </select>
                  <span className="text-danger">
                    {error &&
                      "First Check your username by clicking check button"}
                  </span>
                </>
              ) : (
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tag}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              )}
            </div>

            {/* ====================== PACKAGE ===============  */}
            {/* ====================== PACKAGE ===============  */}
            <div className="bg-fields package-details">
              <h5>
                <LooksTwoIcon className="icon" /> Select Amount
              </h5>
              <div className="package-container">
                {product?.cost?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        setAmount(item.amount);
                        setPriceAndId(item.amount);
                      }}
                      key={index}
                      className={`amount ${
                        amount === item?.amount && "active"
                      }`}
                    >
                      <div className="pack-details">
                        <div className="pack">
                          <p>{item?.amount}</p>
                          <p>{item?.pack}</p>
                        </div>
                        <div className="image">
                          <img src={item?.pimg} alt="pimg" />
                        </div>
                        <div className="price">
                          <p>
                            ₹
                            {user?.reseller === "yes"
                              ? item?.resPrice
                              : item?.price}
                          </p>
                          <p>₹{item?.fakePrice}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* ====================== PAYMENT METHOD ===============  */}
            {/* ====================== PAYMENT METHOD ===============  */}
            <div className="bg-fields">
              <h5>
                <Looks3Icon className="icon" /> Select Payment Channel
              </h5>
              <div className="payment-container">
                <div
                  onClick={() => {
                    setMode("WALLET");
                    setPaymentOptions("");
                  }}
                  className={`payment wallet ${mode === "WALLET" && "active"}`}
                >
                  <WalletIcon className="icon" />
                  WALLET
                </div>
                <div
                  onClick={() => {
                    setMode("UPI");
                    setPaymentOptions("PHONEPE");
                  }}
                  className={`payment phonepe ${
                    paymentOptions === "PHONEPE" && "active"
                  }`}
                >
                  <img src={IMAGES.phonepe} alt="" />
                  PHONEPE
                </div>
                <div
                  onClick={() => {
                    setMode("UPI");
                    setPaymentOptions("GPAY");
                  }}
                  className={`payment gpay ${
                    paymentOptions === "GPAY" && "active"
                  }`}
                >
                  <img src={IMAGES.gpay} alt="" />
                  GPAY
                </div>
                <div
                  onClick={() => {
                    setMode("UPI");
                    setPaymentOptions("PAYTM");
                  }}
                  className={`payment paytm ${
                    paymentOptions === "PAYTM" && "active"
                  }`}
                >
                  <img src={IMAGES.paytm} alt="" />
                  PAYTM
                </div>
              </div>
            </div>

            {/* ========================================= */}
            <div className="bg-fields">
              <div className="total-value">
                <h5>
                  <Looks4Icon className="icon" /> Buy!
                </h5>
                <div className="text-end">
                  <p className="m-0">
                    <b>Rs. {selectedPrice}</b>
                  </p>
                  <span>
                    <small>
                      Amount {amount} |{" "}
                      <span>
                        Using - {paymentOptions !== "" ? paymentOptions : mode}
                      </span>
                    </small>
                  </span>
                </div>
              </div>
              <div className="buy-btn-container">
                {!user ? (
                  <button
                    className="buy-now"
                    onClick={() => navigate("/login")}
                  >
                    Please Login to continue
                  </button>
                ) : user?.block === "yes" || product?.stock === "no" ? (
                  <button className="buy-now" style={{ opacity: "0.7" }}>
                    Out of Stock
                  </button>
                ) : product?.apiName === "smileOne" &&
                  playerCheck &&
                  !userAllow ? (
                  <button className="buy-now disable">
                    This ID is not Allowed
                  </button>
                ) : (
                  <button onClick={checkPlaceOrder} className="buy-now">
                    BUY NOW
                  </button>
                )}
              </div>
            </div>

            <div className="bg-fields d-block d-md-none d-lg-none">
              <p>{product?.descTwo}</p>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ProductInfo;

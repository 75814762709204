import React, { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import PercentIcon from "@mui/icons-material/Percent";
import GamepadIcon from "@mui/icons-material/Gamepad";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WalletIcon from "@mui/icons-material/Wallet";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LogoutIcon from "@mui/icons-material/Logout";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./StickyFooter.css";
import { setUser } from "../../redux/features/userSlice";

const StickyFooter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [menu, setMenu] = useState(false);

  function handleMenu() {
    setMenu(!menu);
  }

  function handleClose(e) {
    e.stopPropagation();
    setMenu(!menu);
  }

  function handleLogout() {
    dispatch(setUser(null));
    localStorage.removeItem("token");
    navigate("/");
  }

  return (
    <div className="sticky-footer d-block d-lg-none">
      <div className="footer-tabs">
        <div
          onClick={() => navigate("/")}
          className={`footer-icon ${location.pathname === "/" && "active"}`}
        >
          <HomeIcon className="icon" />
        </div>
        <div
          onClick={() => navigate("/promo")}
          className={`footer-icon ${
            location.pathname === "/promo" && "active"
          }`}
        >
          <PercentIcon className="icon" />
        </div>
        <div
          onClick={() => navigate("/games")}
          className={`footer-icon ${
            location.pathname === "/games" && "active"
          }`}
        >
          <GamepadIcon className="icon" />
        </div>
        <div onClick={handleMenu} className="footer-icon">
          <MenuIcon className="icon" />
        </div>
      </div>
      {/* MOBILE MENU  */}
      <div
        className={`sticky-mobile-container ${menu && "active"}`}
        onClick={handleClose}
      >
        <div className="sticky-mobile-menu">
          <CancelIcon onClick={() => setMenu(!menu)} className="cancel-icon" />
          <ul className="sticky-menus-ul">
            {user && (
              <>
                <li onClick={() => navigate("/user-dashboard")}>
                  <DashboardIcon className="icon me-2" />
                  Dashboard
                </li>
                <li onClick={() => navigate("/orders")}>
                  <ShoppingCartIcon className="icon me-2" />
                  My Orders
                </li>
                <li onClick={() => navigate("/wallet")}>
                  <WalletIcon className="icon me-2" />
                  My Wallet
                </li>
                <li onClick={() => navigate("/query")}>
                  <Diversity1Icon className="icon me-2" />
                  Trust Pilot
                </li>
              </>
            )}
            <Link to="https://wa.me/917085165780" target="_blank">
              <li>
                <HandshakeIcon className="icon me-2" />
                Reseller Program
              </li>
            </Link>
            <li onClick={() => navigate("/support")}>
              <SupportAgentIcon className="icon me-2" />
              Customer Support
            </li>
            {user && (
              <li onClick={handleLogout}>
                <LogoutIcon className="icon me-2" />
                Logout
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StickyFooter;
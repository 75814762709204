import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, message } from "antd";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import axios from "../Axios";
import AddCardIcon from "@mui/icons-material/AddCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import getUserData from "../utils/userDataService.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ListIcon from "@mui/icons-material/List";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import IMAGES from "../img/image.js";
import { Link } from "react-router-dom";
import "./Wallet.css";

const Wallet = () => {
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(0);
  const [btn, setBtn] = useState(0);
  const [form, setForm] = useState({ email: "", amount: "20" });
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [history, setHistory] = useState(null);
  const [balance, setBalance] = useState("");
  const [price, setPrice] = useState(20);
  const [payment, setPayment] = useState("UPI");

  const coinsArray = [
    {
      coins: 20,
      price: 20,
    },
    {
      coins: 50,
      price: 50,
    },
    {
      coins: 100,
      price: 100,
    },
    {
      coins: 200,
      price: 200,
    },
    {
      coins: 500,
      price: 500,
    },
    {
      coins: 1000,
      price: 1000,
    },
    {
      coins: 2000,
      price: 2000,
    },
    {
      coins: 5000,
      price: 5000,
    },
    {
      coins: 10000,
      price: 10000,
    },
  ];

  useEffect(() => {
    getUserData(null, null, setBalance);
  }, []);

  async function getUserWalletHistory() {
    try {
      const res = await axios.post("/api/wallet/get-wallet-history", {
        email: user?.email,
      });
      if (res.data.success) {
        setHistory(res.data.data.reverse());
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user !== null) {
      getUserWalletHistory();
    }
  }, [user]);

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
  }, []);

  async function submitPayment() {
    try {
      setLoading(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: form?.amount,
        txn_note: "Wallet Topup Note",
        product_name: "Wallet Topup",
        customer_name: form?.customer_name,
        customer_email: form?.customer_email,
        customer_mobile: form?.customer_mobile,
        callback_url: `https://exstorecredit.com/api/payment/status?orderId=${orderId}&email=${form?.customer_email}&mobile=${form?.customer_mobile}&name=${form?.customer_name}`,
      };
      const response = await axios.post(
        "/api/payment/addmoney",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.payment_url) {
        window.location.href = response.data.data.payment_url;
        setLoading(false);
      } else {
        console.log(response.data.message);
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setLoading(false);
    }
  }

  async function getUserPayments() {
    try {
      const res = await axios.post("/api/payment/get-user-payments", {
        email: user?.email,
      });
      if (res.data.success) {
        setPayments(res.data.data.reverse());
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user?.email) {
      setForm((prev) => ({
        ...prev,
        customer_email: user?.email,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
      }));
      getUserPayments();
    }
  }, [user]);

  async function handleChange(e) {
    setForm({ ...form, amount: e.target.value });
  }

  return (
    <Layout>
      <DashboardLayout>
        {tab === 0 && (
          <>
            <div className="walletbalance">
              <span>Total balance</span>
              <p>₹{balance}</p>
              <button onClick={() => setTab(3)}>
                Add Money <AddCircleIcon className="icon" />
              </button>
            </div>
            <div className="txnhistory" onClick={() => setTab(1)}>
              <div>
                <ListIcon className="icon" />
                Transaction History
              </div>
              <div>
                <ArrowForwardIosIcon className="icon" />
              </div>
            </div>
          </>
        )}

        {tab === 1 && (
          <div className="backbtn text-white" onClick={() => setTab(0)}>
            <ArrowBackIosIcon className="icon" />
            <span>Back</span>
          </div>
        )}

        {/* ADD/DEDUCT */}
        {/* ADD/DEDUCT */}
        {tab === 1 && (
          <div className="txn-btns mb-4">
            <button
              onClick={() => setBtn(0)}
              className={`${btn === 0 && "active"}`}
            >
              Addition
            </button>
            <button
              onClick={() => setBtn(1)}
              className={`${btn === 1 && "active"}`}
            >
              Deduction
            </button>
          </div>
        )}

        {/* TXN HISTORY  */}
        {/* TXN HISTORY  */}
        {tab === 1 && btn === 0 && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {payments &&
                payments.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.amount}</td>
                      <td>
                        {new Date(item?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </td>
                      <td>{item.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}

        {/* WALLET HISTORY  */}
        {/* WALLET HISTORY  */}
        {tab === 1 && btn === 1 && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Order ID</th>
                <th>Balance Before</th>
                <th>Balance After</th>
                <th>Price</th>
                <th>Product Info</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {history &&
                history.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.orderId}</td>
                      <td>{item.balanceBefore}</td>
                      <td>{item.balanceAfter}</td>
                      <td>{item.price}</td>
                      <td>{item.p_info}</td>
                      <td>
                        {new Date(item?.created).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}

        {/* ADD COIN */}
        {/* ADD COIN */}
        {/* ADD COIN */}
        {tab === 3 && (
          <>
            <div className="add-coin-container">
              <div className="backbtn text-white" onClick={() => setTab(0)}>
                <ArrowBackIosIcon className="icon" />
                <span>Back</span>
              </div>
              <div className="addmount">
                <h4>Enter Amount to Add</h4>
                <input
                  type="number"
                  name="amount"
                  placeholder="Enter amount"
                  value={form?.amount}
                  onChange={(e) => {
                    handleChange(e);
                    setPrice(e.target.value);
                  }}
                  className="form-control mb-3"
                />
              </div>
              <h4>Select your amount</h4>
              <div className="coin-container">
                {coinsArray?.map((item, index) => {
                  return (
                    <div
                      className={`coin ${price === item?.price && "active"}`}
                      onClick={() => {
                        setPrice(item?.price);
                        setForm({ ...form, amount: item?.price });
                      }}
                    >
                      {/* <p>{parseFloat(item?.coins).toFixed(2)} Coins</p> */}
                      <p>₹{parseFloat(item?.price).toFixed(2)}</p>
                    </div>
                  );
                })}
              </div>
              <div className="mt-4 pack-info payment-containerr">
                <div className="title">
                  <p>Choose the payment method</p>
                </div>
                <div className="payment">
                  <div
                    onClick={() => {
                      setPayment("UPI");
                    }}
                    className={`upi ${payment === "UPI" && "active"}`}
                  >
                    <div>
                      <img src={IMAGES.upi} alt="" />
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setPayment("PAYTM");
                    }}
                    className={`upi ${payment === "PAYTM" && "active"}`}
                  >
                    <div>
                      <img src={IMAGES.paytm} alt="" />
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setPayment("GPAY");
                    }}
                    className={`upi ${payment === "GPAY" && "active"}`}
                  >
                    <div>
                      <img src={IMAGES.gpay} alt="" />
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setPayment("PHONEPE");
                    }}
                    className={`upi ${payment === "PHONEPE" && "active"}`}
                  >
                    <div>
                      <img src={IMAGES.phonepe} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="buy-now confirmbtn w-100"
                onClick={submitPayment}
              >
                Confirm
              </button>
              <p className="mt-2 text-white text-center">
                <small>
                  By clicking "Confirm" agree to{" "}
                  <Link to="/terms">User's Terms and Conditions</Link> &{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </small>
              </p>
              <div className="d-flex payment-secured">
                <VerifiedUserIcon className="icon" />
                <span>
                  Payment <br /> Secured
                </span>
              </div>
            </div>
          </>
        )}
      </DashboardLayout>
    </Layout>
  );
};

export default Wallet;

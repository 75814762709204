import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useSelector } from "react-redux";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import FeedbackIcon from "@mui/icons-material/Feedback";
import "../Footer/Footer.css";
import StickyFooter from "./StickyFooter";
import IMAGES from "../../img/image.js";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment>
      <div className="before-footer-support">
        <span>Customer Support</span>
        <h2>Reach Us At</h2>
        <div className="support-options">
          <div className="options text-center">
            <Link
              className="column"
              to="https://wa.me/917085165780"
              target="_blank"
            >
              <WhatsAppIcon className="icon text-success" />
              <span>Whatsapp</span>
            </Link>
          </div>
          <div className="options">
            <Link
              className="column"
              target="_blank"
              to="mailto:arunkumarkhomdram71@gmail.com"
            >
              <EmailIcon className="icon" />
              <span>Email</span>
            </Link>
          </div>
          {/* <div className="options" onClick={() => navigate("/support")}>
            <HelpCenterIcon className="icon" />
            <span>FAQ</span>
          </div> */}
          <div className="options" onClick={() => navigate("")}>
            <Link
              className="column"
              target="_blank"
              to="mailto:arunkumarkhomdram71@gmail.com"
            >
              <FeedbackIcon className="icon" />
              <span>Feedback</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-subscribe">
        <span>Subscribe</span>
        <h2>Get The Best Deals Now!</h2>
        <div className="social-media-links social">
          <Link
            target="_blank"
            to="https://www.instagram.com/exstore_credit?igsh=MTZybGx1cWFidHMzZQ=="
          >
            <InstagramIcon className="icon" />
          </Link>
          <Link
            target="_blank"
            to="https://www.facebook.com/profile.php?id=61556244120229&mibextid=ZbWKwL"
          >
            <FacebookSharpIcon className="icon" />
          </Link>
          <Link target="_blank" to="https://wa.me/917085165780">
            <WhatsAppIcon className="icon" />
          </Link>
          <Link target="_blank" to="mailto:arunkumarkhomdram71@gmail.com">
            <EmailIcon className="icon" />
          </Link>
        </div>
      </div>
      <div className="container-fluid footer-container">
        <div className="wa-icon-cont">
          <Link target="_blank" to="https://wa.me/917085165780">
            <WhatsAppIcon className="icon" />
          </Link>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <div className="footer-logo">
              <b>
                EXSTORE<span className="ffca00">CREDIT</span>
              </b>
            </div>
            <span>
              <small>
                Exstore Credit is the leading platform for digital gaming goods
                and other services. We provide the best & fastest Non-Stop
                Services 24/7
              </small>
            </span>
            <br />
            <br />
            <span>+91-7085165780</span>
            <br />
            {/* <span>Manipur , Bishnupur District, 795134</span> */}
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Quick Links</h6>
            <hr />
            <ul>
              {!user && (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </>
              )}
              {user && (
                <li>
                  <Link to="/user-dashboard">Dashboard</Link>
                </li>
              )}
              <li>
                <Link to="/support">Customer Support</Link>
              </li>
              <li>
                <Link to="/track-order">Track Order</Link>
              </li>
            </ul>
          </div>
          <div className="quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Important Pages</h6>
            <hr />
            <ul>
              {/* <li>
                <Link to="/faq">Frequently Asked Questions</Link>
              </li> */}
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
            </ul>
          </div>
          <div className="quick-links col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <h6>Payment Channels</h6>
            <hr />
            <div className="payment-channels">
              <marquee className="p-chan" behavior="smooth" direction="left">
                <img src={IMAGES.wallet} alt="" />
                <img src={IMAGES.upi} alt="" />
                <img src={IMAGES.phonepe} alt="" />
                <img src={IMAGES.gpay} alt="" />
                <img src={IMAGES.paytm} alt="" />
              </marquee>
            </div>
          </div>
          <hr />
          <span className="">
            <small>
              All Rights Reserved © 2024 | EXSTORE CREDIT
            </small>
          </span>
        </div>
      </div>
      <StickyFooter />
    </React.Fragment>
  );
};

export default Footer;
import React from "react";
import "./Maintenance.css";
import IMAGES from "../img/image";

const Maintenance = () => {
  return (
    <div className="maintenance-container">
      <img src={IMAGES.maint} alt="" />
      <h1 className="m-0 text-center">
        <b>
          Website Under <br /> Maintenance..
        </b>
      </h1>
    </div>
  );
};

export default Maintenance;
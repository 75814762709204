import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import SideMenu from "./SideMenu";
import Backdrop from "./Backdrop";
import axios from "../../Axios";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import SearchContainer from "../SearchContainer";
import SideStickyMenu from "../SideStickyMenu.js";
import getUserData from "../../utils/userDataService.js";
import ArrowOutwardSharpIcon from "@mui/icons-material/ArrowOutwardSharp";
import IMAGES from "../../img/image";
import WalletIcon from "@mui/icons-material/Wallet";
import "./Header.css";

const Header = () => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sideMenu, setSideMenu] = useState(false);
  const [cartMenu, setCartMenu] = useState(false);
  const [balance, setBalance] = useState("");
  const [search, setSearch] = useState(null);
  const [products, setProducts] = useState(null);
  const [enteredSearch, setEnteredSearch] = useState(null);

  const handleSearch = (e) => {
    setEnteredSearch(e.target.value);
    if (e.target.value === "") {
      setEnteredSearch(null);
    }
  };
  const searchProduct =
    products &&
    products?.filter((obj) =>
      obj.name.toLowerCase().includes(enteredSearch?.toLowerCase())
    );

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  const getAllProducts = async () => {
    try {
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data.reverse());
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <>
      <header className="header">
        <div className="header-main">
          {/* <div
            className="burger-icon d-block d-lg-none"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <MenuIcon className="icon" />
          </div> */}
          <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <Backdrop sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <div className="logo" onClick={() => navigate("/")}>
            <img src={IMAGES.logo} alt="" />
            {/* <span>
              EXSTORE<span className="ffca00">CREDIT</span>
            </span> */}
          </div>

          <div className="search-bar hero-search-container d-none d-md-none d-lg-block">
            <input
              type="text"
              className="hero-search-input"
              placeholder="Search Trips"
              onChange={handleSearch}
            />
            {enteredSearch === null ? (
              ""
            ) : searchProduct?.length === 0 ? (
              <div className="search-box-empty">No Result Found</div>
            ) : (
              <div className="search-box">
                {searchProduct?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="search-items"
                      onClick={() => navigate(`/product/${item?.name}`)}
                    >
                      <span>{item?.name}</span>
                      <span>
                        <ArrowOutwardSharpIcon className="icon" />
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="action-btns">
            {!user && location.pathname !== "/login" && (
              <button onClick={() => navigate("/login")} className="theme-btn">
                Sign In
              </button>
            )}
            {!user && location.pathname === "/login" && (
              <button
                onClick={() => navigate("/register")}
                className="theme-btn"
              >
                Sign Up
              </button>
            )}
            {user && (
              <div className="user-wallet" onClick={() => navigate("/wallet")}>
                <WalletIcon className="icon" />
                {parseFloat(balance).toFixed(2)}₹
              </div>
            )}
          </div>
        </div>
      </header>
      <SearchContainer search={search} setSearch={setSearch} />
      <SideStickyMenu />
    </>
  );
};

export default Header;